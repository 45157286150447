<template>
  <div class="row">

    <div class="col-md-12">
      <Card title="Profile Saya">
        <template v-slot:body>
          <div class="row align-items-center">

            <!-- Profile Image -->
            <div class="col-md-4">
              <div class="d-flex justify-content-center align-items-center image-container">
                <div style='height: 350px; width: 350px; position: relative'>
                  <img
                    class="image"
                    v-if="data != null"
                    :src="data.photo"
                    @load="onImageLoad()"
                  >
                </div>
              </div>
            </div>

            <!-- Table Profile Data -->
            <div
              class="col"
              v-if="imgLoaded"
            >
              <table class="table mb-5">
                <tr>
                  <td style="width: 25%"><strong>Nama</strong></td>
                  <td>{{ data.name }}</td>
                </tr>
                <tr>
                  <td style="width: 25%"><strong>Email</strong></td>
                  <td>{{ data.email }}</td>
                </tr>
                <tr>
                  <td style="width: 25%"><strong>Handphone</strong></td>
                  <td>{{ data.mobile_phone }}</td>
                </tr>
                <tr>
                  <td style="width: 25%"><strong>Poliklinik</strong></td>
                  <td>{{ data.polyclinic_name }}</td>
                </tr>
                <tr>
                  <td style="width: 25%"><strong>Alamat</strong></td>
                  <td>{{ data.address }}</td>
                </tr>
              </table>
              <button
                @click="$router.push({path: '/profile/change-password'})"
                class="btn mx-1 btn-success"
              ><i class="fas fa-fw mb-1 mr-1 fa-unlock-alt"></i> Edit Password</button>
              <button
                @click="$router.push({path: '/profile/change-profile'})"
                class="btn mx-1 btn-info"
              ><i class="fas fa-fw mb-1 mr-1 fa-user-edit"></i> Edit Profile</button>
            </div>

          </div>
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import { getUser } from '@/core/services/jwt.service.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card
  },

  data() {
    return {
      // Data
      data: getUser(),
      // Other
      imgLoaded: false
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile", route: "" },
    ])
  }

}
</script>

<style>
</style>